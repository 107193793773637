@import '../../base'

.header-brand-name
  background: var(--header_brandBg)
  @include flex
  justify-content: flex-end

  .toggle-sidebar-btn
    min-width: 40px
    height: 42px!important
    border: 0
    border-radius: 0
    color: white!important
    background: var(--header_brandBg)
    border-left: 1px solid var(--header_brandSeparator)!important
    svg
      path
        fill: var(--btn_color)
    &:not(:disabled):not(.ant-btn-disabled)
      &:hover
        background: var(--btn_primaryHoverBg)
      &:active
        background: var(--btn_primaryActiveBg)

#left-sidebar .brand-name
  text-transform: uppercase
  color: var(--btn_color)
  font-size: 14px
  @include bold
  margin: 0 auto

#left-sidebar
  width: 180px!important
  min-width: 180px!important
  max-width: 180px!important
  background: var(--sideBar_bg)
  padding-bottom: 30px

  &.ant-layout-sider-collapsed
    width: 40px!important
    min-width: 40px!important
    max-width: 40px!important

  .sidebar-app-version
    color: rgba(255,255,255,.4)

#sidebar-menu
  border-top: 1px solid var(--sideBar_borderDark)
  border-bottom: 1px solid var(--sideBar_borderLight)
  overflow: auto
  max-height: calc(100% - 42px)
  background: var(--sideBar_bg)

  &::-webkit-scrollbar-thumb, .rc-virtual-list-scrollbar-thumb
    background-color: rgba(var(--primaryTxtColor),.3) !important

  > .menu-item.ant-menu-item-selected, > .menu-item.ant-menu-item-group, > .menu-item.ant-menu-submenu-selected
    background: var(--sideBar_itemSelectedBg)!important

    .ant-menu-item-group-title, .ant-menu-title-content
      color: #fff
      @include bold
      padding: 0
      @include flex
      margin: 0

    .ant-menu-item-icon, .ant-menu-item-group-title > span
      background: var(--header_brandBg)!important
      width: 40px
      min-width: 40px
      height: 100%
      border-right: 1px solid var(--sideBar_itemSelectedBorderDark)
      box-shadow: 1px 0 var(--sideBar_borderLight)
      @include flex
      justify-content: center
      margin-right: 12px

      svg
        path
          fill: var(--btn_color)!important

  li:not(.ant-menu-submenu-vertical) div[data-menu-id="sidebar-menu-reports"]
    pointer-events: none
    .ant-menu-submenu-arrow
      display: none

  > .ant-menu-item, .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-item-group-title
    height: 38px
    line-height: 38px

  .menu-item:not(.ant-menu-item-only-child)
    background: var(--sideBar_itemBg)
    border-top: 1px solid var(--sideBar_borderLight)
    border-bottom: 1px solid var(--sideBar_borderDark)
    margin: 0

  .menu-item, .ant-menu-item, .ant-menu-submenu-title
    margin: 0
    width: 100%
    padding: 0 !important
    border-radius: 0

  .ant-menu-item-icon
    width: 40px
    height: 100%
    border-right: 1px solid var(--sideBar_borderDark)
    box-shadow: 1px 0 var(--sideBar_borderLight)
    @include flex
    justify-content: center
    margin-right: 12px
    svg
      path
        fill: rgb(var(--primaryTxtColor))

    + .ant-menu-title-content
      color: rgb(var(--primaryTxtColor))
      margin-inline-start: 0

  .ant-menu-submenu-title .ant-menu-submenu-arrow
    right: 8px
    &:before, &:after
      background: rgb(var(--primaryTxtColor))
      height: 1.2px
      opacity: 1

  .ant-menu-submenu-selected, .ant-menu-submenu-open
    background: var(--sideBar_itemSelectedBg)!important

    .ant-menu-submenu-title, &:not(.ant-menu-item-only-child)
      .ant-menu-title-content
        color: #fff

      .ant-menu-submenu-arrow
        &:before, &:after
          background: #fff

    > .ant-menu-title-content, .ant-menu-submenu-title
      @include bold

    .ant-menu-item-icon
      svg
        path
          fill: #fff

  .ant-menu-item-group-list, .ant-menu-sub
    background: var(--sideBar_subMenuBg)
    color: rgb(var(--primaryTxtColor))
    border-top: 1px solid var(--sideBar_borderDark)

    .ant-menu-item
      color: rgb(var(--primaryTxtColor))
      padding: 0 0 0 40px !important

      .ant-menu-title-content
        padding-left: 12px
        font-weight: normal
      &:hover a
        color: #fff

      &.ant-menu-item-selected
        background: transparent
        color: var(--sideBar_itemSelectedColor)

        a.active,
        .ant-menu-title-content > a
          color: var(--sideBar_itemSelectedColor)
          @include bold

        .ant-menu-title-content
          background: #fff
          box-shadow: inset 2px 0 var(--sideBar_itemSelectedColor)

    .ant-menu-item, .ant-menu-title-content
      height: 30px
      line-height: 30px

      > span > a
        color: rgb(var(--primaryTxtColor))

  .ant-menu-submenu-title:hover, .ant-menu-item.menu-item:not(.ant-menu-item-only-child):hover
    .ant-menu-title-content
      color: #fff
      @include bold

    .ant-menu-item-icon svg path
      fill: #fff

.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub
  background: var(--sideBar_subMenuBg)
  border-radius: 3px
  min-width: 100px

  .ant-menu-item
    height: 36px
    line-height: 36px
    padding: 0 10px
    margin: 0
    width: 100%
    &.ant-menu-item-selected
      background: var(--sideBar_itemSelectedBg)!important
      .ant-menu-title-content > *
        color: #fff

  .ant-menu-title-content > *
    color: rgb(var(--primaryTxtColor))

.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right
  color: rgb(var(--primaryTxtColor))
  padding: 0
  .ant-tooltip-arrow
    display: none
  .ant-tooltip-inner
    background: #344150!important
    border-radius: 3px
    min-height: 36px
    line-height: 36px
    padding: 0 10px
    color: rgb(var(--primaryTxtColor))
    min-width: 100px

.sidebar-app-version
  color: var(--primaryColor)
  padding-top: 6px
  text-align: center
  font-size: 11px
  position: absolute
  width: 100%
  bottom: 10px
