@import './../../base'

.page-breadcrumb
  @include flex
  position: relative
  z-index: 10
  a
    color: rgba(#fff, .8)
    height: auto
    &:hover
      color: #fff
  &.ant-breadcrumb > span:last-child a, &.ant-breadcrumb li:last-child a, .ant-breadcrumb-separator
    color: rgba(var(--primaryTxtColor))
